import { ColorClass, Header1, joinClasses, useWindowDimensions } from "ui";
import { useState } from "react";
import styles from "./css/LandingCardExamplesSection.module.css";
import LandingCardButtons from "@web/pages/landing/LandingCardButtons";
import LandingCardExamplesInPhone from "@web/pages/landing/LandingCardExamplesInPhone";
import LandingCardExamples from "@web/pages/landing/LandingCardExamples";

export function LandingCardExamplesSection() {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { width } = useWindowDimensions();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Header1
          className={joinClasses(styles.header, styles.margins)}
          colorClass={ColorClass.Primary}
        >
          <span>Use Harken to</span>{" "}
          <span className={ColorClass.Highlight}>
            remember anything for a lifetime.
          </span>
        </Header1>
        <div className={joinClasses(styles.subheader, styles.margins)}>
          Harken is way more efficient than conventional study methods, and
          supports all kinds of learning. You can put almost anything on a
          card—images, audio, inline code, or code blocks.
        </div>
        <div className={styles.buttonsAndExamples}>
          <LandingCardButtons
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <div>
            {width >= 1024 ? (
              <LandingCardExamplesInPhone selectedIndex={selectedIndex} />
            ) : (
              <LandingCardExamples selectedIndex={selectedIndex} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
