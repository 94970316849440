export function isFromNative(): boolean {
  if (typeof window !== "undefined" && window.sessionStorage) {
    try {
      return JSON.parse(sessionStorage.getItem("harkenFromNative") || "");
    } catch (e) {}
  }
  return false;
}

export function setFromNative() {
  if (typeof window !== "undefined" && window.sessionStorage) {
    try {
      sessionStorage.setItem("harkenFromNative", JSON.stringify(true));
    } catch (e) {}
  }
}
