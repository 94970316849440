import { joinClasses } from "ui";
import styles from "./css/ModalContent.module.css";

type Props = {
  children: any;
  className?: string;
  width?: number;
};

export function ModalContent({ children, className, width = 400 }: Props) {
  return (
    <div className={joinClasses(styles.form, className)} style={{ width }}>
      {children}
    </div>
  );
}
