import { CardMarkdown } from "@web/components/card/CardMarkdown";
import styles from "./css/LandingCardExamples.module.css";

type Props = {
  selectedIndex: number;
};

export default function LandingCardExamples({ selectedIndex }: Props) {
  const cardContent = [
    { back: "スパー", front: "Supermarket" },
    {
      back: <div>Nafcillin and oxacillin are alternatives to methicillin.</div>,
      front: (
        <div>_________ and _________ are alternatives to methicillin.</div>
      ),
    },
    {
      back: <div>Favorable</div>,
      front: (
        <div>
          What does <b>auspicious</b> mean?
        </div>
      ),
    },
    // TODO: fill out!
    {
      back: <pre style={{ display: "inline" }}>&apos;Bob&apos;</pre>,
      front: (
        <div style={{ fontSize: 18, lineHeight: "20px", textAlign: "center" }}>
          After this executes,
          <br /> what is <pre style={{ display: "inline" }}>x.name</pre>?
          <div style={{ fontSize: 14, lineHeight: "16px", textAlign: "left" }}>
            <CardMarkdown
              cardMarkdownContent={`
\`\`\`javascript
let x = {name: 'May'}
let y = x
y.name = 'Bob'
\`\`\`
      `}
            />
          </div>
        </div>
      ),
    },
    {
      back: (
        <div>
          <i>The Persistence of Memory</i>, Salvador Dalí
        </div>
      ),
      // eslint-disable-next-line @next/next/no-img-element
      front: <img src="/images/landing/dali.png" alt="Dali" />,
    },
    {
      back: (
        <div>
          Years to double
          <br /> &#8773; <br />
          72 &#247; interest rate
        </div>
      ),
      front: "What is the rule of 72?",
    },
  ];

  const cards = cardContent.map((content) => (
    <>
      <div className={styles.cardFrontBack}>{content.front}</div>
      <hr className={styles.hr} />
      <div className={styles.cardFrontBack}>{content.back}</div>
    </>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.cardPreview}>{cards[selectedIndex]}</div>
    </div>
  );
}
