import { ColorClass, joinClasses } from "ui";
import styles from "./css/LandingReviewStats.module.css";

type Props = {
  type: "traditional" | "harken";
};

const CONTENT = {
  harken: {
    description: "Review on our spaced repetition schedule for 20 years",
    time: "5 minutes",
    title: "Harken cards:",
  },
  traditional: {
    description: "Review once a week for 20 years",
    time: "2+ hours",
    title: "Traditional flashcards:",
  },
};

export function LandingReviewStats({ type }: Props) {
  const content = CONTENT[type];
  return (
    <div className={styles.container}>
      <div
        className={joinClasses(
          styles.title,
          type === "harken" ? ColorClass.Highlight : ColorClass.Ghost
        )}
      >
        {content.title}
      </div>
      <div className={styles.time}>{content.time}</div>
      <div className={styles.description}>{content.description}</div>
    </div>
  );
}
