import { ContactModal } from "@web/components/modals/ContactModal";
import { dayjsex } from "@web/utils/dates/dayjsex";
import { useState } from "react";
import { ColorClass, FieldTitle, FlexBox, FontClass, TextButton } from "ui";
import styles from "./css/Footer.module.css";

function Options({ children }: { children: any }) {
  return (
    <FlexBox flexDirection="column" gap={12} style={{ marginTop: 16 }}>
      {children}
    </FlexBox>
  );
}

function GetInTouchSection() {
  const [isContactModalShown, setIsContactModalShown] = useState(false);

  return (
    <>
      <ContactModal
        isShown={isContactModalShown}
        onHide={() => setIsContactModalShown(false)}
      />
      <div className={styles.getInTouchSection}>
        <FieldTitle className={styles.getInTouchTitle} colorClass={null}>
          Get in Touch
        </FieldTitle>
        <Options>
          <TextButton
            buttonThemeOrColorClass={ColorClass.White}
            fontClass={FontClass.Body1Medium}
            href="https://twitter.com/pencilflip"
            type="link_external"
          >
            Follow Us on Twitter
          </TextButton>
          <div id="contact">
            <TextButton
              buttonThemeOrColorClass={ColorClass.White}
              fontClass={FontClass.Body1Medium}
              onClick={() => setIsContactModalShown(true)}
            >
              Contact Us
            </TextButton>
          </div>
        </Options>
      </div>
    </>
  );
}

function LogoAndCopyrightSection() {
  return (
    <div className={styles.logoAndCopyrightSection}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className={styles.sokiLogo}
        src="/images/harken-logo.svg"
        alt="Logo"
      />
      <div className={styles.copyright}>&copy; {dayjsex().year()} Harken</div>
    </div>
  );
}

function ResourcesSection() {
  return (
    <div className={styles.resourcesSection}>
      <FieldTitle className={styles.resourcesTitle} colorClass={null}>
        Resources
      </FieldTitle>
      <Options>
        <TextButton
          buttonThemeOrColorClass={ColorClass.White}
          fontClass={FontClass.Body1Medium}
          href="/about"
          type="link_internal"
        >
          Our Story
        </TextButton>
        {/* <a className={styles.link} href="/alternatives">
        <div className={styles.resourcesItem}>Harken vs. Alternatives</div>
      </a> */}
        <TextButton
          buttonThemeOrColorClass={ColorClass.White}
          fontClass={FontClass.Body1Medium}
          href="/blog/spaced-repetition"
          type="link_internal"
        >
          What Is Spaced Repetition
        </TextButton>
        <TextButton
          buttonThemeOrColorClass={ColorClass.White}
          fontClass={FontClass.Body1Medium}
          href="/blog"
          type="link_internal"
        >
          Blog
        </TextButton>
        <TextButton
          buttonThemeOrColorClass={ColorClass.White}
          fontClass={FontClass.Body1Medium}
          href="/privacy-policy"
          type="link_internal"
        >
          Privacy Policy
        </TextButton>
      </Options>
    </div>
  );
}

export function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <ResourcesSection />
        <GetInTouchSection />
        <LogoAndCopyrightSection />
      </div>
    </div>
  );
}
