import { LandingFeatureCard } from "@web/pages/landing/LandingFeatureCard";
import { ColorClass, joinClasses } from "ui";
import styles from "./css/LandingWhyHarkenSection.module.css";

export function LandingWhyHarkenSection() {
  return (
    <div className={styles.container}>
      <div className={joinClasses(styles.titleFont, ColorClass.Secondary)}>
        Why Harken?
      </div>
      <div className={styles.description}>
        Every feature is designed to support your long-term learning.
      </div>
      <div className={styles.featureCards}>
        <LandingFeatureCard
          description="Harken’s card editor includes support for rich text, images, inline code, and code blocks. No matter what you’re trying to remember, Harken is here to support you."
          imagePath="/images/landing/feature-cards/flexible.png"
          subtitle="Flexible"
          title="Format however you like."
        />
        <LandingFeatureCard
          description="Harken is a responsive web app, meaning you can review and create cards on any device."
          imagePath="/images/landing/feature-cards/ever-present.png"
          subtitle="Easy Access"
          title="Your cards go where you go."
        />
        {/* <HomeFeatureCard
          description="We’ll automatically back up your cards, and you can restore your cards from any recent backup. You’ll never have to worry about losing your knowledge base."
          imagePath="/images/landing/feature-cards/reliable.png"
          subtitle="Reliable"
          title="Never lose your cards."
        /> */}
        <LandingFeatureCard
          description="We know your time is valuable. If you’re already using Anki, you can import all your decks and review history to Harken in just a few clicks. You’ll be up and running in no time."
          imagePath="/images/landing/feature-cards/convenient.png"
          subtitle="Convenient"
          title="Easily import your Anki decks."
        />
        <LandingFeatureCard
          description="There’s no better feeling than a sense of progress. Harken’s stats page turns your reviews into useful charts to help you visualize just how much you’ve remembered."
          imagePath="/images/landing/feature-cards/stats.jpg"
          subtitle="Motivating"
          title="Track your progress."
        />
        {/* <HomeFeatureCard
          description="You own your cards. Harken lets you export your data, giving you complete freedom to manually store backups or even transfer the data into a separate app."
          imagePath="/images/landing/feature-cards/export.jpg"
          subtitle="Unrestricting"
          title="Export your data."
        /> */}
      </div>
    </div>
  );
}
