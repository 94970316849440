import { joinClasses, useWindowDimensions } from "ui";
import styles from "./css/LandingTimeline.module.css";

type TimelineLabelProps = {
  weekNumber: number;
};

function TimelineLabel({ weekNumber }: TimelineLabelProps) {
  return (
    <div className={styles.label}>
      <span className={styles.labelWeek}>Week</span>
      <br />
      <span className={styles.labelNumber}>{weekNumber}</span>
    </div>
  );
}

function TimelinePoint() {
  return <div className={styles.point} />;
}

type Props = {
  type: "traditional" | "harken";
};

export function LandingTimeline({ type }: Props) {
  const { width } = useWindowDimensions();

  return (
    <div
      className={joinClasses(
        styles.container,
        type === "traditional"
          ? styles.traditionalContainer
          : styles.harkenContainer
      )}
    >
      <div>
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
        <TimelinePoint />
      </div>
      <div>
        <TimelineLabel weekNumber={1} />
        <TimelineLabel weekNumber={2} />
        <TimelineLabel
          // eslint-disable-next-line no-nested-ternary
          weekNumber={type === "traditional" ? 3 : width < 800 ? 5 : 4}
        />
        <TimelineLabel weekNumber={type === "traditional" ? 4 : 7} />
        <TimelineLabel weekNumber={type === "traditional" ? 5 : 11} />
        <TimelineLabel weekNumber={6} />
        <TimelineLabel weekNumber={7} />
        <TimelineLabel weekNumber={8} />
        <TimelineLabel weekNumber={9} />
        <TimelineLabel weekNumber={10} />
        <TimelineLabel weekNumber={11} />
      </div>
      <div className={styles.timeline} />
    </div>
  );
}
