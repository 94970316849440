import styles from "./css/LandingExtensionSection.module.css";
import {
  ColorClass,
  GlobalClass,
  Header1,
  TextButton,
  joinClasses,
  useWindowDimensions,
} from "ui";

export function LandingExtensionSection() {
  const { width } = useWindowDimensions();
  // For some reason, Next.js won't render the mobile image if you refresh on a mobile screen size
  // and if we use useBreakpoints here instead
  const src =
    width <= 768
      ? "/images/landing/extension-screenshot-mobile.webp"
      : "/images/landing/extension-screenshot.webp";

  return (
    <div className={styles.container}>
      <Header1
        className={styles.title}
        colorClass={ColorClass.Primary}
        textAlign="center"
      >
        Use the Harken extension to remember everything you read
      </Header1>
      <div className={joinClasses(styles.description, styles.descriptionFont)}>
        {/* TODO: add link to extension */}
        Now you can remember anything from any article on the web. The{" "}
        <TextButton
          buttonThemeOrColorClass={ColorClass.Secondary}
          className={styles.descriptionFont}
          fontClass={undefined}
          display="inline"
          href="https://chrome.google.com/webstore/detail/harken/dicbhciopdcalpokgbchgbnoaadbjdfd"
          textDecoration="underline"
          type="link_external"
        >
          Harken Chrome extension
        </TextButton>{" "}
        allows you to quiz yourself on content from any article on the internet
        by using AI to generate questions with a single click. As you answer
        questions, easily add the ones you care about to your Harken for future
        review.
      </div>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="Extension"
        className={joinClasses(styles.image, GlobalClass.HideText)}
        key={src}
        src={src}
      />
    </div>
  );
}
