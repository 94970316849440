import { joinClasses } from "ui";
import styles from "./css/LandingCardButtons.module.css";

type HomeCardButtonProps = {
  isSelected: boolean;
  label: string;
  onClick: () => void;
};

function HomeCardButton({ isSelected, label, onClick }: HomeCardButtonProps) {
  return (
    <button
      className={joinClasses(
        styles.button,
        isSelected ? styles.buttonSelected : styles.buttonUnselected
      )}
      onClick={onClick}
      type="button"
    >
      <div>{label}</div>
    </button>
  );
}

type Props = {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
};

export default function HomeCardButtons({
  selectedIndex,
  setSelectedIndex,
}: Props) {
  const buttonLabels = [
    "Learning Japanese",
    "Studying for medical exams",
    "Memorizing vocab",
    "Mastering JavaScript",
    "Remembering famous works of art",
    "Learning personal finance",
  ];

  const buttons = buttonLabels.map((label, index) => (
    <HomeCardButton
      key={label}
      isSelected={index === selectedIndex}
      label={label}
      onClick={() => setSelectedIndex(index)}
    />
  ));

  return (
    <div className={styles.container}>
      {buttons}
      {/* Needed because margin-right on last button doesn't expand the wrapper outwards.
      See https://stackoverflow.com/questions/11695354/css-right-margin-does-not-work-inside-a-div-with-overflow-scroll */}
      <div className={styles.endDiv}>a</div>
    </div>
  );
}
