import styles from "./css/LandingFeatureCard.module.css";
import Image from "next/image";
import { ColorClass, joinClasses } from "ui";

type Props = {
  description: string;
  imagePath: string;
  subtitle: string;
  title: string;
};

export function LandingFeatureCard({
  description,
  imagePath,
  subtitle,
  title,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image
          alt={title}
          height={350}
          layout="responsive"
          src={imagePath}
          width={857}
        />
      </div>
      <div className={styles.textSection}>
        <div className={joinClasses(styles.subtitle, ColorClass.Highlight)}>
          {subtitle}
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
}
