import { ModalContent } from "@web/components/modals/ModalContent";
import { Body1, ColorClass, FontClass, Modal, TextButton } from "ui";

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export function ContactModal({ isShown, onHide }: Props) {
  return (
    <Modal isShown={isShown} onHide={onHide} title="Contact Us">
      <ModalContent>
        <Body1 colorClass={ColorClass.Secondary} textAlign="center">
          If you have any questions, or need support using Harken, please{" "}
          <TextButton
            buttonThemeOrColorClass={ColorClass.Highlight}
            display="inline"
            fontClass={FontClass.Body1}
            href="https://t.me/pencilflip"
            type="link_external"
          >
            contact us on Telegram
          </TextButton>
          ! We should get back to you in a few hours.
        </Body1>
      </ModalContent>
    </Modal>
  );
}
