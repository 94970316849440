import Image from "next/image";
import { ButtonWithText, ButtonTheme, FontClass, joinClasses } from "ui";
import styles from "./css/LandingTopSection.module.css";
import headerImage from "public/images/landing/header-image.png";
import Link from "next/link";
import { isFromNative } from "@web/utils/env/isFromNative";

export function LandingTopSection() {
  const fromNative = isFromNative();
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.menu}>
          <Link href="/">
            <Image
              className={styles.harkenLogo}
              height={51}
              src="/images/harken-logo.svg"
              width={160}
              alt="Logo"
            />
          </Link>
          {/* <HomeContactButton /> */}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.header}>
            Never forget what you learn again.
          </div>
          <div
            className={joinClasses(
              styles.subheader,
              "separatorWithShadowTextColor"
            )}
          >
            Harken is a smart flashcard app that uses spaced repetition to make
            remembering easy.
          </div>
          <ButtonWithText
            buttonTheme={ButtonTheme.Mauve}
            className={styles.betaButton}
            fontClass={FontClass.Body1Medium}
            href={fromNative ? "/nativeregister" : "/register"}
            type="link_internal"
            style={{ width: 200 }}
          >
            Try it out &nbsp;&#x1F64C;
          </ButtonWithText>
        </div>
        <div className={styles.imageContent}>
          <Image
            alt="Hero image"
            className={styles.headerImage}
            src={headerImage}
          />
        </div>
      </div>
    </div>
  );
}
