import { ColorClass, joinClasses } from "ui";
import { LandingReviewStats } from "@web/pages/landing/LandingReviewStats";
import { LandingTimeline } from "@web/pages/landing/LandingTimeline";
import styles from "./css/LandingHowDoesItWorkSection.module.css";

export function LandingHowDoesItWorkSection() {
  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.topContainerInner}>
          <div className={joinClasses(styles.topTitle, ColorClass.Secondary)}>
            How does it work?
          </div>
          <div className={styles.topDescription}>
            Harken uses a science-backed learning strategy called{" "}
            <span className={ColorClass.Highlight}>spaced repetition</span> to
            optimize your studying, so you can learn more in less time.
          </div>
          <div className={styles.topExplanation}>
            With spaced repetition, you’ll review unfamiliar concepts more
            frequently than concepts you already know. This means you’ll learn
            exponentially faster with Harken than with traditional study
            methods.
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.squigglyTop} />
        <div className={styles.bottomTitle}>
          To remember one concept for 20 years, how long would you need to
          study?
        </div>
        <div className={styles.charts}>
          <div className={styles.timelineSection1}>
            <div className={styles.timelineTitle}>
              With traditional studying, you might review it once per week:
            </div>
            <LandingTimeline type="traditional" />
          </div>
          <div className={styles.timelineSection2}>
            <div className={styles.timelineTitle}>
              But with Harken, as you get more familiar with a concept, your
              reviews will get more spaced out:
            </div>
            <LandingTimeline type="harken" />
          </div>
        </div>
        <div className={styles.reviewStats}>
          <LandingReviewStats type="traditional" />
          <div className={styles.reviewStatsHarken}>
            <LandingReviewStats type="harken" />
          </div>
        </div>
        <div className={styles.squigglyBottom} />
      </div>
    </>
  );
}
