import { Footer } from "@web/components/footers/Footer";
import { LandingCardExamplesSection } from "@web/pages/landing/LandingCardExamplesSection";
import { LandingExtensionSection } from "@web/pages/landing/LandingExtensionSection";
import { LandingHowDoesItWorkSection } from "@web/pages/landing/LandingHowDoesItWorkSection";
import { LandingTopSection } from "@web/pages/landing/LandingTopSection";
import { LandingWhyHarkenSection } from "@web/pages/landing/LandingWhyHarkenSection";

export default function LandingPage(): JSX.Element {
  return (
    <div>
      <LandingTopSection />
      <LandingCardExamplesSection />
      <LandingHowDoesItWorkSection />
      <LandingExtensionSection />
      <LandingWhyHarkenSection />
      <Footer />
    </div>
  );
}
