import CardMarkdownErrorBoundary from "./CardMarkdownErrorBoundary";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import htmlbars from "react-syntax-highlighter/dist/cjs/languages/hljs/htmlbars";
import idea from "react-syntax-highlighter/dist/cjs/styles/hljs/idea";
import js from "react-syntax-highlighter/dist/cjs/languages/hljs/javascript";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("htmlbars", htmlbars);
SyntaxHighlighter.registerLanguage("python", python);

type Props = {
  // Markdown content (may also contain HTML).
  cardMarkdownContent: string;
};

/**
 * Converts a CodeMirror mode value to a Highlighter JS language.
 *
 * @param mode a value from https://codemirror.net/mode/
 * @return a value from https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
 */
function codeMirrorModeToHljsLanguage(mode: string): string {
  switch (mode) {
    case "htmlmixed":
      return "htmlbars";
    default:
      return mode;
  }
}

/**
 * Renders a card's markdown content.
 */
export function CardMarkdown({ cardMarkdownContent }: Props) {
  const renderers = {
    code: ({ language, value }: { language: string; value: any }) => {
      return (
        <SyntaxHighlighter
          style={idea}
          language={codeMirrorModeToHljsLanguage(language)}
          // If the code block is empty, value will be undefined.
          // Passing undefined causes a runtime error.
          // eslint-disable-next-line react/no-children-prop
          children={value ?? ""}
          showLineNumbers
        />
      );
    },
  };

  // The HTML generated by https://ankiweb.net/shared/info/1463041493 does not play nicely with
  // react-markdown.
  if (cardMarkdownContent.match(/table class="highlighttable"/g)) {
    return <div dangerouslySetInnerHTML={{ __html: cardMarkdownContent }} />;
  }

  return (
    <CardMarkdownErrorBoundary cardMarkdownContent={cardMarkdownContent}>
      <ReactMarkdownWithHtml renderers={renderers} allowDangerousHtml>
        {cardMarkdownContent}
      </ReactMarkdownWithHtml>
    </CardMarkdownErrorBoundary>
  );
}
