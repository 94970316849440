import { Component, ErrorInfo } from "react";

type Props = {
  // Markdown content (may also contain HTML).
  cardMarkdownContent: string;
  children: any;
};

type State = {
  hasError: boolean;
};

/**
 * This provides a fallback UI when CardMarkdown fails to render.
 * This may happen when you search for something like "table" and thus the HTML gets messed
 * up like <<mark>table</mark ...>.
 *
 * TODO: may want to improve highlighting implementation and try not to highlight stuff in the
 * middle of HTML tags. Currently, the fallback UI has a chance to look a bit weird.
 */
export default class CardMarkdownErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO: eventually, should log to server
    console.log("caught error: error = ", error);
    console.log("caught error: errorInfo = ", errorInfo);
    console.log("cardMarkdownContent: ", this.props.cardMarkdownContent);
  }

  render() {
    if (this.state.hasError) {
      // If using react-markdown fails, fall back to dangerouslySetInnerHTML.
      return (
        <div
          dangerouslySetInnerHTML={{ __html: this.props.cardMarkdownContent }}
        />
      );
    }

    return this.props.children;
  }
}
