import Image from "next/image";
import styles from "./css/LandingCardExamplesInPhone.module.css";
import {
  ColorClass,
  ColorValue,
  CrossIcon,
  EditNoteIcon,
  FieldTitle,
} from "ui";
import LandingCardExamples from "@web/pages/landing/LandingCardExamples";

function CardStudyInfo() {
  return (
    <div className={styles.cardStudyInfo}>
      <CrossIcon colorValue={ColorValue.Secondary} size={24} />
      <FieldTitle colorClass={ColorClass.Secondary}>10 cards left</FieldTitle>
      <EditNoteIcon colorValue={ColorValue.Secondary} />
    </div>
  );
}

type Props = {
  selectedIndex: number;
};

export default function LandingCardExamplesInPhone({ selectedIndex }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <Image
          alt="Phone"
          height={629}
          src="/images/landing/iphone11.png"
          width={464}
        />
      </div>
      <CardStudyInfo />
      <div className={styles.phoneBg} />
      <div className={styles.examples}>
        <LandingCardExamples selectedIndex={selectedIndex} />
      </div>
    </div>
  );
}
